<template>
  <!--begin::Chart widget 27-->
  <div class="card card-flush h-xl-100">
    <!--begin::Body-->
    <div class="card-body pt-0 pb-1">
      <div class="d-flex flex-column flex-root" id="kt_app_root">
        <!--begin::Authentication - Password reset -->
        <div class="d-flex flex-column flex-lg-row flex-column-fluid">
          <!--begin::Aside-->
          <div class="d-flex flex-lg-row-fluid">
            <!--begin::Content-->
            <div
              class="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100"
            >
              <!--begin::Image-->
              <img
                class="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
                src="/assets/media/auth/agency.png"
                alt=""
              />
              <img
                class="theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
                src="/assets/media/auth/agency-dark.png"
                alt=""
              />
              <!--end::Image-->
              <!--begin::Title-->
              <h1 class="text-gray-800 fs-2qx fw-bold text-center mb-7">
                Pengecekan Kartu Tanda Anggota TNI
              </h1>
              <!--end::Title-->
              <!--begin::Text-->
              <div class="text-gray-600 fs-base text-center fw-semibold">
                Semua Kartu Tanda Anggota TNI telah terdaftar di sistem yang
                dikelola TNI dan dapat di cek keasliannya dengan cara memindai
                QR Qode yang terdapat pada Kartu Tanda Anggota TNI, atau dengan
                cara memasukkan nomor NRP yang tertera pada Kartu Tanda Anggota
                TNI pada sistem ini.
              </div>
              <!--end::Text-->
            </div>
            <!--end::Content-->
          </div>
          <!--begin::Aside-->
          <!--begin::Body-->
          <div
            class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12"
          >
            <!--begin::Wrapper-->
            <div
              class="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10"
            >
              <!--begin::Content-->
              <div
                class="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px"
              >
                <!--begin::Wrapper-->
                <div
                  class="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20"
                >
                  <!--begin::Form-->
                  <form
                    class="form w-100"
                    novalidate="novalidate"
                    id="kt_password_reset_form"
                    data-kt-redirect-url="../../demo30/dist/authentication/layouts/overlay/new-password.html"
                    action="#"
                  >
                    <!--begin::Heading-->
                    <div class="text-center mb-10">
                      <!--begin::Title-->
                      <h1 class="text-dark fw-bolder mb-3">
                        Periksa Kartu Tanda Anggota
                      </h1>
                      <!--end::Title-->
                      <!--begin::Link-->
                      <div class="text-gray-500 fw-semibold fs-4">
                        Silahkan masukan nomor NRP.
                      </div>
                      <!--end::Link-->
                    </div>
                    <!--begin::Heading-->
                    <!--begin::Input group=-->
                    <div class="fv-row mb-8">
                      <input
                        type="text"
                        placeholder="No. NRP"
                        autocomplete="off"
                        class="form-control bg-transparent"
                      />
                    </div>
                    <!--begin::Actions-->
                    <div
                      class="d-flex flex-wrap justify-content-center pb-lg-0"
                    >
                      <a
                        class="btn btn-light-success me-4"
                        href="http://89.233.105.27:8081/kta/verification?11950052590873=Z2JyZ0NqQlZQblIwT294TlFnYWNPelJwRUppeThLTWZDKy9XNTBjNU5EWT0="
                      >
                        <span class="indicator-label">Periksa</span>
                      </a>
                      <a href="index.html" class="btn btn-light">Batal</a>
                    </div>
                    <!--end::Actions-->
                  </form>
                  <!--end::Form-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Body-->
        </div>
        <!--end::Authentication - Password reset-->
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
export default {};
</script>
